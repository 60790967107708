<template>
  <!-- 系列介绍页 -->
  <div class="series">
    <div class="s_center" v-if="this.seriesData!==null">
      <nav>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/course', query: {id: 0, } }">课程案例</el-breadcrumb-item>
          <el-breadcrumb-item>{{seriesData.seriseName}}</el-breadcrumb-item>
        </el-breadcrumb>
      </nav>
      <header>
        <div class="h_left">
          <img :src="seriesData.picture.url" alt />
          <!-- <img src="../../../assets/components/Group 107.png" alt /> -->
          <div class="h_icon">
            <p class="h_p_like">
              <i class="like" v-if="!praiseFlag" @click="messagePraise"></i>
              <i class="likes" v-else @click="deletePraise"></i>
              <span>{{seriesData.praiseCount}}</span>
            </p>
            <p class="h_p_star">
              <i class="star" v-if="!collectionFlag" @click="messageCollection"></i>
              <i class="stars" v-else @click="messageDeleteCollection"></i>
              <span>{{seriesData.collectionCount}}</span>
            </p>
          </div>
        </div>
        <div class="h_right">
          <p class="r_title">{{seriesData.seriseName}}</p>
          <p class="r_subject">系列课程建立者：{{seriesData.createUser}}</p>
          <p class="r_time">包含课程数量：{{seriesData.courseCount}}</p>
          <p class="r_user">创建时间：{{seriesData.createTime}}</p>
        </div>
      </header>
      <main>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="系列课程简介" name="first">
            <p class="one">{{seriesData.content}}</p>
          </el-tab-pane>
          <el-tab-pane label="课程列表" name="second">
            <div class="two">
              <card v-for="item in seriesData.course" :key="item.id" :carddata="item" :cardsite="0"></card>
            </div>
          </el-tab-pane>
        </el-tabs>
      </main>
    </div>
  </div>
</template>
<script>
import card from "../../../components/card.vue";
export default {
  data() {
    return {
      // 系列课程tabs
      activeName: "first",
      // 详情数据
      seriesData: null,
      // 点赞
      collectionFlag: false, //收藏
      praiseFlag: false, //点赞
    };
  },
  created() {
    console.log(this.$route.query.id, "ahf");
    this.seriselist(this.$route.query.id);
    this.queryMessageStatus(this.$route.query.id);
  },
  methods: {
    // 获取系列课程
    async seriselist(id) {
      let formdata = new FormData();
      formdata.append("seriseId", id);
      const { data, status } = await this.$Http.querySeriseCourseDetail(
        formdata
      );
      if (status == 200) {
        console.log(data, status, "系列课程详情");
        const res = await this.$Http.querySeriseCourseDetailCourse({
          courseIdList: data.list[0].courseList,
          seriseId: id,
        });
        if (res.status == 200) {
          console.log(res, "获取普通课程");
          let datas = data.list[0];
          datas.course = res.data.list;
          console.log(datas, "datas");
          this.seriesData = datas;
        }
        return;

        let datas = data.list[0];
        let course = [];
        for (let x = 0; x < data.list[0].courseList.length; x++) {
          console.log(data.list[0].courseList[x], "safkjla");
          let datas = JSON.stringify({
            courseIdList: [data.list[0].courseList[x]], //课程id集合
            courseType: 1, //课程类型(1-课程，2-资料)
            sortId: 1, //排序ID(1-上传时间，2-播放量，3-评价数量，4-收藏数量，5-点赞数量，6-下载量)
            sortType: 1, //排序类型（1-正序，2-倒序）
          });
          await this.$Http.querySeriseCourseDetailCourse(datas).then((res) => {
            console.log(res, "普通课程");
            course.push(res.data.list[0]);
          });
        }
        console.log(course, "course");
        datas.course = course;
        this.seriesData = datas;
      }
    },

    // ---
    // 获取点赞和收藏的情况
    async queryMessageStatus(id) {
      const { data, status } = await this.$Http.queryMessageStatus({
        serviceId: id,
        type: 2, //1-普通课程/资料 2-系列课程 3-教研圈 4-活动 5-广场
      });
      if (status == 200) {
        console.log(data, status, "点赞和收藏");
        this.collectionFlag = data.collectionFlag;
        this.praiseFlag = data.praiseFlag;
      }
    },
    // 点赞
    async messagePraise() {
      let id = this.$route.query.id;
      const { data, status } = await this.$Http.messagePraise({
        serviceId: id,
        type: 2,
      });
      if (status == 200) {
        // console.log(data, status, "点赞");
        this.queryMessageStatus(id);
        this.seriselist(id);
      }
    },
    // 取消点赞
    async deletePraise() {
      let id = this.$route.query.id;
      const { data, status } = await this.$Http.deletePraise({
        serviceId: id,
        type: 2,
      });
      if (status == 200) {
        // console.log(data, status, "取消点赞");
        this.queryMessageStatus(id);
        this.seriselist(id);
      }
    },
    // 收藏
    async messageCollection() {
      let id = this.$route.query.id;
      const { data, status } = await this.$Http.messageCollection({
        serviceId: id,
        type: 2,
      });
      if (status == 200) {
        // console.log(data, status, "收藏");
        this.queryMessageStatus(id);
        this.seriselist(id);
      }
    },
    // 取消收藏
    async messageDeleteCollection() {
      let id = this.$route.query.id;
      const { data, status } = await this.$Http.messageDeleteCollection({
        serviceId: id,
        type: 2,
      });
      if (status == 200) {
        // console.log(data, status, "取消收藏");
        this.queryMessageStatus(id);
        this.seriselist(id);
      }
    },

    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
  components: {
    card,
  },
};
</script>
<style lang="scss" scoped>
.series {
  .s_center {
    width: 1200px;
    margin: 0 auto;
    nav {
      padding: 15px 0px;
    }
    header {
      // width: 1200px;
      // height: 365px;
      padding: 20px;
      background: #ffffff;
      display: flex;
      justify-content: space-between;
      .h_left {
        img {
          width: 500px;
          height: 300px;
        }
        .h_icon {
          display: flex;
          justify-content: flex-start;
          .h_p_like {
            .like {
              cursor: pointer;
              display: inline-block;
              width: 30px;
              height: 27px;
              background: url("../../../assets/icon/blanklike.png");
            }
            .likes {
              cursor: pointer;
              display: inline-block;
              width: 30px;
              height: 27px;
              background: url("../../../assets/icon/solidlike.png");
            }
            span {
              display: inline-block;
              //   width: 29px;
              //   height: 21px;
              font-size: 16px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #141516;
              //   line-height: 19px;
              vertical-align: super;
            }
          }
          .h_p_star {
            margin-left: 40px;

            .star {
              cursor: pointer;
              display: inline-block;
              width: 30px;
              height: 27px;
              background: url("../../../assets/icon/blankstar.png");
            }
            .stars {
              cursor: pointer;
              display: inline-block;
              width: 30px;
              height: 27px;
              background: url("../../../assets/icon/solidestar.png");
            }
            span {
              display: inline-block;
              //   width: 29px;
              //   height: 21px;
              font-size: 16px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #141516;
              //   line-height: 19px;
              vertical-align: super;
            }
          }
        }
      }
      .h_right {
        width: 720px;
        height: 280px;
        padding: 10px 20px;
        // display: flex;
        // justify-content: flex-start;
        // flex-direction: column;
        .r_title {
          max-width: 720px;
          //   height: 29px;
          font-size: 22px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          //   line-height: 26px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 40px;
        }

        p {
          // width: 80px;
          // height: 21px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #5d6066;
          // line-height: 19px;
          margin-bottom: 20px;
        }
        .el-button {
          width: 160px;
        }
      }
    }
    main {
      margin-top: 25px;
      background-color: #fff;
      padding: 10px 20px;
      .one {
        // width: 384px;
        // height: 21px;
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #141516;
        // line-height: 19px;
      }
      .two {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .card {
          margin: 7px;
        }
      }
    }
  }
}
</style>